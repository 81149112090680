<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col col="6" offset="0">
          <h1>{{ name }}</h1>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="getSecureRentData"
      :search="search"
    >
    
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="reloadPage">
  <v-icon>  {{ icons.mdiReload }}</v-icon>
</v-btn>
          <v-dialog v-model="dialog" fullscreen hide-overlay>
            <v-card>
              <v-toolbar flat class="custom-toolbar">
                <v-btn icon @click="close">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <!-- Toolbar Content Wrapped in Grid System -->
                <v-col>
                  <v-row>
                    <v-toolbar-title>Medien zum Vorgang: {{ selectedName }} </v-toolbar-title>
                  </v-row>

                </v-col>
                <v-spacer></v-spacer>
                <v-radio-group v-model="imageFilter" row class="toolbar-radio-group">
                      <v-radio label="Abholung" value="picked"></v-radio>
                      <v-radio label="Rückgabe" value="returned"></v-radio>
                    </v-radio-group>
              </v-toolbar>
              <v-spacer></v-spacer>
             
              <v-divider></v-divider>

              <v-container class="my-5">
                <div class="mt-5">
                  <v-row class="justify-center">
                    <v-col
                      v-for="(src, i) in filteredSecureRentImageSources"
                      :key="i"
                      class="d-flex child-flex"
                      cols="4"
                    >
                      <v-img
                        :src="src"
                        width="300"
                        @click="
                          selectedItem = src;
                          show();
                        "
                      ></v-img>
                    </v-col>
                  </v-row>
                </div>
              </v-container>

              <v-overlay v-if="selectedItem">
                <v-img
                  :src="selectedItem"
                  contain
                  @click="selectedItem = null"
                ></v-img>
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon dark color="warning" v-if="item.status == 1">
          {{ icons.mdiAlertCircle }}
        </v-icon>
        <v-icon ddark color="success" v-else-if="item.status == 0">
          {{ icons.mdiCheckCircle }}
        </v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.image_path != null && item.image_path != ''"
          class="mx-2"
          fab
          dark
          small
          rounded
          color="primary"
          @click="showImage(item, item.name)"
        >
          <v-icon dark>
            {{ icons.mdiCameraImage }}
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.finalStatus`]="{ item }">
        <v-icon dark color="success" v-if="item.picked && item.returned">
          {{ icons.mdiCheckCircle }}
        </v-icon>
        <v-icon dark color="orange" v-else>
          {{ icons.mdiAlertCircleOutline }}
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<style>
.custom-toolbar {
  height: 180px; /* Or whatever height suits your design */
}
.toolbar-radio-group {
  margin-top: 30px; /* Adjust for alignment */
}
</style>
<script>
import {
  mdiCameraImage,
  mdiCheckCircle,
  mdiAlertCircle,
  mdiPencil,
  mdiClose,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiReload
} from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
export default {
  props: { // Add a prop to receive the name
    selectedName: {
      type: String,
      default: ''
    }
  },
  components: {
    //VueCoreVideoPlayer,
  },
  data() {
    return {
      imageFilter: "picked",
      name: "SecureRent",
      selectedItem: null,
      visible: false,
      icons: {
        mdiCameraImage,
        mdiCheckCircle,
        mdiAlertCircle,
        mdiPencil,
        mdiClose,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiReload
      },
      statuses: [
        {
          name: "Aktiv",
          value: 1,
        },
        {
          name: "Inaktiv",
          value: 0,
        },
        {
          name: "",
          value: 2,
        },
      ],
      search: "",
      headers: [
        {
          text: "Medien",
          align: "start",
          filterable: false,
          value: "action",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Trans-Nr", value: "trans_token" },
        { text: "Abgeholt", value: "picked" },
        { text: "Rückgabe", value: "returned" },
        { text: "Vorg. abgeschl.", value: "status" },
      ],
      currentItem: {
        id: null,
        name: "",
        email: "",
        trans_token: "",
        picked: "",
        returned: "",
        status: 2,
      },
      defaultItem: {
        id: null,
        name: "",
        email: "",
        trans_token: "",
        picked: "",
        returned: "",
        status: 2,
      },
      dialog: false,
      editmodal: false,
    };
  },
  computed: {
    ...mapGetters("main", ["getSecureRentData", "secureRentImageSources"]),
    filteredSecureRentImageSources() {
      return this.secureRentImageSources.filter((src) =>
        this.imageFilter === "picked"
          ? src.includes("/picked/")
          : src.includes("/returned/")
      );
    },
  },
  methods: {
    ...mapActions("main", [
      "loadSecureRentData",
      "retrieveSecureRentImageSources",
    ]),

    reloadPage() {
      window.location.reload();
    },
    showImage(item) {
  this.currentItem = { ...this.defaultItem, ...item }; // Simplified object assignment
  this.retrieveSecureRentImageSources(item.id);
  this.dialog = true;
  this.selectedName = item.name; // Ensure the selectedName is updated with the item's name
},
    close() {
      this.dialog = false;
      this.selectedItem = null;
      this.$store.commit("main/CLEAR_SECURERENT_IMAGE_SOURCES"); // Add this line to clear images
      this.$nextTick(() => {
        this.currentItem = Object.assign({}, this.defaultItem);
      });
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  mounted() {
    this.$store.dispatch("main/loadSecureRentData");
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
