var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"col":"6","offset":"0"}},[_c('h1',[_vm._v(_vm._s(_vm.name))])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getSecureRentData,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.reloadPage}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiReload))])],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"custom-toolbar",attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1),_c('v-col',[_c('v-row',[_c('v-toolbar-title',[_vm._v("Medien zum Vorgang: "+_vm._s(_vm.selectedName)+" ")])],1)],1),_c('v-spacer'),_c('v-radio-group',{staticClass:"toolbar-radio-group",attrs:{"row":""},model:{value:(_vm.imageFilter),callback:function ($$v) {_vm.imageFilter=$$v},expression:"imageFilter"}},[_c('v-radio',{attrs:{"label":"Abholung","value":"picked"}}),_c('v-radio',{attrs:{"label":"Rückgabe","value":"returned"}})],1)],1),_c('v-spacer'),_c('v-divider'),_c('v-container',{staticClass:"my-5"},[_c('div',{staticClass:"mt-5"},[_c('v-row',{staticClass:"justify-center"},_vm._l((_vm.filteredSecureRentImageSources),function(src,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":src,"width":"300"},on:{"click":function($event){_vm.selectedItem = src;
                          _vm.show();}}})],1)}),1)],1)]),(_vm.selectedItem)?_c('v-overlay',[_c('v-img',{attrs:{"src":_vm.selectedItem,"contain":""},on:{"click":function($event){_vm.selectedItem = null}}})],1):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
                          var item = ref.item;
return [(item.status == 1)?_c('v-icon',{attrs:{"dark":"","color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")]):(item.status == 0)?_c('v-icon',{attrs:{"ddark":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [(item.image_path != null && item.image_path != '')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.showImage(item, item.name)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCameraImage)+" ")])],1):_vm._e()]}},{key:"item.finalStatus",fn:function(ref){
                          var item = ref.item;
return [(item.picked && item.returned)?_c('v-icon',{attrs:{"dark":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_c('v-icon',{attrs:{"dark":"","color":"orange"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircleOutline)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }